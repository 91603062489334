import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta} from '@angular/platform-browser';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { environment } from '../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {CanonicalService} from '../../../canonical.service';
import { Location } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FeaturedNewsComponent } from './featured-news/featured-news.component';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ActivationEnd } from '@angular/router';
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  MetaTagLoaded: boolean;
  MetaTagData: any;
  envSiteName:any;
  GlobalNewsTopAD:SafeResourceUrl;
  GlobalNewsADAfterSpotlight:SafeResourceUrl;
  GlobalFeaturedNewsTopAD:SafeResourceUrl;
  GlobalFeaturedNewsADAfterSpotlight:SafeResourceUrl;
  GlobalTrendingNewsTopAD:SafeResourceUrl;
  GlobalTrendingNewsADAfterSpotlight:SafeResourceUrl;
 
  gnewsSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalNewsADAfterSpotlight.html';
  gnewstop:string='https://'+environment.siteName+'/ADcodeFile/GlobalNewsTopAD.html'
  fnewsSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalFeaturedNewsADAfterSpotlight.html';
  fnewstop:string='https://'+environment.siteName+'/ADcodeFile/GlobalFeaturedNewsTopAD.html'
  tnewsSpo:string='https://'+environment.siteName+'/ADcodeFile/GlobalTrendingNewsADAfterSpotlight.html';
  tnewstop:string='https://'+environment.siteName+'/ADcodeFile/GlobalTrendingNewsTopAD.html'

  fnurl:String= "https://"+window.location.hostname+"/news/featured-news";
  tnurl:string = "https://"+window.location.hostname+"/news/trending-news";
  currentpageeurl:string=window.location.href;


  constructor(private router: Router,private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,
    private sanitizer:DomSanitizer,private canonical:CanonicalService,private Location:Location,public routerLink:RouterModule)
   { 
    this.envSiteName=environment.siteName; 
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd))
      .subscribe(e => {
            this.MetaData((e as ActivationEnd).snapshot);
      });
  
  }

  ngOnInit(): void {
    // this.AllMetaTagNews();
    this.GlobalNewsADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.gnewsSpo);
    this.GlobalNewsTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.gnewstop);
    this.GlobalFeaturedNewsADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.fnewsSpo);
    this.GlobalFeaturedNewsTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.fnewstop);
    this.GlobalTrendingNewsADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.tnewsSpo);
    this.GlobalTrendingNewsTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.tnewstop);
    //this.NewsAD();
  }

  // @HostListener('click') 
  //  NewsAD(): void{

  //   // alert(this.fnurl);
  //   if(this.fnurl == window.location.href){
  //      document.getElementById('fnews').classList.add('displayblock');
  //      //document.getElementById('newsspo').classList.add('displaynone');
  //   }else if(this.tnurl == window.location.href){
  //     document.getElementById('tnews').classList.add('displayblock'); 
  //     //document.getElementById('fnewsspo').classList.add('displaynone'); 
  //   }else{
  //     document.getElementById('news').classList.add('displayblock'); 
  //     //document.getElementById('tnewsspo').classList.remove('displaynone'); 
  //    }
     
  // }
  MetaData(snapshot: ActivatedRouteSnapshot): void {
    let parent: ActivatedRouteSnapshot = snapshot.parent as ActivatedRouteSnapshot;
    let path: string = 'News';
    let componentName : string;
    if (typeof(parent.component)==='function')
    {
      componentName = parent.component.name;
    }
    if(componentName != 'NewsComponent') 
      return;

    if (snapshot.routeConfig.path) {
      path = snapshot.routeConfig.path;
    }
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName=' + this.envSiteName + '&PageName=' + path)
      .subscribe(
        items => {
          this.MetaTagData = items[0];
          this.MetaTagLoaded=true;
        
            //this.canonical.updateCanonicalUrl(this.currentpageeurl);
            //seo code for tags
           this.canonical.CreateCanonicalLink();
  
            this.titleService.setTitle(this.MetaTagData.PageTitle);
  
            this.metaTagService.updateTag(
              {name:'robots',content:'index,follow'}
            );
  
            this.metaTagService.updateTag(
              { name: 'description', content: this.MetaTagData.OgDescription}
              );

              this.metaTagService.updateTag(
                { name: 'keywords', content: this.MetaTagData.OgKeyWords}
              );
              
            this.metaTagService.updateTag(
            { name: 'og:description', content: this.MetaTagData.OgDescription}
            );
           
            this.metaTagService.updateTag(
              { name: 'og:title', content: this.MetaTagData.PageTitle}
            );
  
            this.metaTagService.updateTag(
              { name: 'og:keyword', content: this.MetaTagData.OgKeyWords}
            );
  
            this.metaTagService.updateTag(
              { name: 'og:image', content: this.MetaTagData.OgImageURL}
            );
  
            this.metaTagService.updateTag(
              { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
            );
            this.metaTagService.updateTag(
              { name: 'twitter:description', content: this.MetaTagData.OgDescription}
            );
            this.metaTagService.updateTag(
              { name: 'twitter:title', content: this.MetaTagData.PageTitle}
            );
            this.metaTagService.updateTag(
              { name: 'twitter:keyword', content: this.MetaTagData.OgKeyWords}
            );
            this.metaTagService.updateTag(
              { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
            );
          //seo code for tags end
        });
  }

  AllMetaTagNews(): void {
    if(this.MetaTagLoaded!=true){
    this.MetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'News')
    .subscribe(
      items => {
        this.MetaTagData = items[0];
        this.MetaTagLoaded=true;
        //console.log('this.MetaTagData',this.MetaTagData);

        
          //this.canonical.updateCanonicalUrl(this.currentpageeurl);
          //seo code for tags
         this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.MetaTagData.PageTitle);

          this.metaTagService.updateTag(
            {name:'robots',content:'index,follow'}
          );

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.MetaTagData.OgDescription}
          );
         
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.MetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:keyword', content: this.MetaTagData.OgKeyWords}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.MetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.MetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:keyword', content: this.MetaTagData.OgKeyWords}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }
}
