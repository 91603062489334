<p class="mb-3 mt-2 para-2-c-2" *ngIf="singleEventLoaded">
    <a routerLink="/">Home</a> > <a routerLink="/events">Event</a> >
    <a routerLink="/events/{{singleWPastEvents.EventDifferentType}}" class="text-capitalize"> Past Conferences</a> >
    <span class="para-2-c-1" [innerHTML]=singleWPastEvents.Name></span>
</p>
<div *ngIf="singleEventLoaded" class="mb-5">
    <h1 [innerHTML]="singleWPastEvents.Name"></h1>
    <p class="para-4-bold">{{singleWPastEvents.DateWithStartTime}} | {{singleWPastEvents.Country}}</p>
    <img class="img-fluid" src="{{singleWPastEvents.ImageUrl}}" alt="{{singleWPastEvents.Name}}">
    <p class="pt-3 para-6 mb-0 text-justify" [innerHTML]="singleWPastEvents.Details"></p>
</div>
<app-other-past-conference (refreshEmitter)="receiveRefresh($event)"></app-other-past-conference>
<div class="text-center w-100">
    <button class="thm-btn mt-4 btn-theme-success w-50 pt-3 pb-3" routerLink="/events/past-conferences">More Past Conferences</button>
</div>
<div class="share-div" (click)="shareClick()">
    <i class="fa fa-share-alt" aria-hidden="true"></i>
</div>
<div class="share-in" [@openClose]="isOpen ? 'open' : 'closed'">
    <a href="https://www.facebook.com/sharer.php?u={{fullUrl}}" target="_blank">
        <div class="share-inner f-icon">
            <i class="fab fa-facebook-f"></i>
        </div>
    </a>
    <a href="https://www.linkedin.com/shareArticle?mini=true&url={{fullUrl}}" target="_blank">
        <div class="share-inner l-icon">
            <i class="fab fa-linkedin-in"></i>
        </div>
    </a>
    <a href="https://twitter.com/share?url={{fullUrl}}" target="_blank">
        <div class="share-inner t-icon">
            <i class="fab fa-twitter"></i>
        </div>
    </a>
</div>