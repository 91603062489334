import { Injectable } from '@angular/core';
import { HttpclientService } from './../../application/httpclient/httpclient.service';
import { environment } from '../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  public liveWebinarFound: boolean;
  public onDemandWebinarFound: boolean;
  public upComingConferencesFound: boolean;
  public pastConferencesFound: boolean;
  private envSiteName: string = environment.siteName;
  constructor(private httpclientService: HttpclientService) {
    this.envSiteName = environment.siteName;
    this.liveWebinarFound = false;
    this.onDemandWebinarFound = false;
    this.upComingConferencesFound = false;
    this.pastConferencesFound = false;
    this.checkLiveWebinar();
    this.checkOnDemandWebinar();
    this.checkUpComingConferences();
    this.checkPastConferences();
  }
  checkLiveWebinar() {
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllLiveWebinars?siteName=' + this.envSiteName + '&pageNumber=' + 1)
      .subscribe(
        items => {
          this.liveWebinarFound = true;
        },
        err => {
          console.log(err);
        }
      );
  }
  checkOnDemandWebinar() {
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllOnDemandWebinars?siteName=' + this.envSiteName + '&pageNumber=' + 1)
      .subscribe(
        items => {
          this.onDemandWebinarFound = true;
        },
        err => {
          console.log(err);
        }
      );
  }
  checkUpComingConferences() {
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllUpCommingConferences?siteName=' + this.envSiteName + '&pageNumber=' + 1)
      .subscribe(
        items => {
          this.upComingConferencesFound = true;
        },
        err => {
          console.log(err);
        }
      );
  }
  checkPastConferences() {
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllPastConferences?siteName=' + this.envSiteName + '&pageNumber=' + 1)
      .subscribe(
        items => {
          this.pastConferencesFound = true;
        }, err => {
          console.log(err);
        }
      );
  }
}
