<h1 class="text-uppercase mb-0 mt-md-3">News</h1>
<ul class="nav nav-tabs mobTab" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link active pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="home-tab" data-toggle="tab" href="#news-tab1" role="tab" aria-controls="home" aria-selected="true">Top Stories</a>
    </li>
    <li class="nav-item tab-width">
        <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="profile-tab" data-toggle="tab" href="#news-tab2" role="tab" aria-controls="profile" aria-selected="false">Latest</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5" id="profile-tab" data-toggle="tab" href="#news-tab3" role="tab" aria-controls="profile" aria-selected="false">ARCHIVED NEWS</a>
    </li> -->

</ul>
<div class="tab-content mb-0" id="myTabContent">
    <div class="tab-pane fade show active" id="news-tab1" role="tabpanel" aria-labelledby="home-tab">
        <!-- mobile view slider -->
        <div class="col-12 d-block d-md-none pt-3 p-0 ">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigFeatureNews" *ngIf="featureLoaded">
                <div ngxSlickItem *ngFor="let featureNewsData of featureNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
                    <a class="para-2 mb-0 CatTag CustCatBtn" *ngIf="featureNewsData.Tag!=' '; else BlankTag" [href]="getTagUrl(featureNewsData.Tag)" target="_blank">{{featureNewsData.Tag}}</a>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                    <h4 class="text-height-3 card-header-height-1 TagTitle" [innerHTML]="featureNewsData.Title">
                    </h4>
                    <p class="para-2 mb-1 sponDate">{{featureNewsData.PublishingDate}} </p>
                    <p class="para-1 text-justify text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 Read_More" href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div>
        <!-- mobile view slider end -->
        <div class="row d-none d-md-flex" *ngIf="featureLoaded">
            <div class="col-md-4 mb-5 custPara" *ngFor="let featureNewsData of featureNewsDatas">
                <!-- <div class="cursor-p" routerLink='{{featureNewsData.EventDifferentType}}/{{featureNewsData.RouteURL}}'> -->
                <a target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType | lowercase }}/{{featureNewsData.RouteURL}}'> 
                    <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
                </a>    
                    <a class="para-2 mb-0 text-height-1 CustCatBtn" *ngIf="featureNewsData.Tag!=' '; else BlankTag" [href]="getTagUrl(featureNewsData.Tag)" target="_blank">{{featureNewsData.Tag}}</a>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                <a target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType | lowercase }}/{{featureNewsData.RouteURL}}'  class="custom-tooltip"> 
                    <!-- <span class="titleBox mt-2"> {{featureNewsData.Title}} </span>  -->
                    <h4 class="text-height-title-3" title="{{featureNewsData.Title}}" [innerHTML]="featureNewsData.Title"></h4>
                </a>
                    <p class="para-2 mb-0">{{featureNewsData.CompanyName}} <span *ngIf='featureNewsData.CompanyName!="" && featureNewsData.PublishingDate!=""'>|</span> {{featureNewsData.PublishingDate}} 
                        <!-- <span *ngIf="featureNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span> -->
                    </p>
                    <p class="para-1 text-left text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType | lowercase }}/{{featureNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="thm-btn mt-md-0 btn-theme-success pt-3 pb-3 GNewsbtn" routerLink="featured-news">More Top Stories</button>
        </div>
    </div>
    <div class="tab-pane pt-2 fade" id="news-tab2" role="tabpanel" aria-labelledby="profile-tab">
        <!-- mobile view slider -->
        <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigTrendingNews" *ngIf="trendingLoaded">
                <div ngxSlickItem *ngFor="let trendingNewsData of trendingNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <a class="para-2 mb-0 CatTag CustCatBtn" *ngIf="trendingNewsData.Tag!=''; else BlankTag" [href]="getTagUrl(trendingNewsData.Tag)" target="_blank">{{trendingNewsData.Tag}}</a>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                    <h4 class="text-height-4 card-header-height-1 TagTitle">{{trendingNewsData.Title}}</h4>
                    <p class="para-2 mb-1 sponDate">{{trendingNewsData.Date}} </p>
                    <p class="para-1 text-justify text-height-4 DescPara">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2 Read_More" href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div>
        <!-- mobile view slider end -->
        <div class="row d-none d-md-flex" *ngIf="trendingLoaded">
            <div class="col-md-4 mb-5 custPara" *ngFor="let trendingNewsData of trendingNewsDatas">
                <!-- <div class="cursor-p" routerLink='{{trendingNewsData.EventDifferentType}}/{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
                <a target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'> 
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                </a>    
                    <a class="para-2 mb-0 text-height-1 CustCatBtn" *ngIf="trendingNewsData.Tag!=''; else BlankTag" target="_blank" [href]="getTagUrl(trendingNewsData.Tag)" target="_blank">{{trendingNewsData.Tag}}</a>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                <a target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}' class="custom-tooltip">     
                    <!-- <span class="titleBox mt-2"> {{trendingNewsData.Title}} </span>  -->
                    <h4 class="text-height-title-3" title="{{trendingNewsData.Title}}" [innerHTML]="trendingNewsData.Title"></h4>
                </a>
                    <p class="para-2 mb-0">{{trendingNewsData.CompanyName}} <span *ngIf='trendingNewsData.CompanyName!="" && trendingNewsData.PublishingDate!=""'>|</span> {{trendingNewsData.PublishingDate}} 
                        <!-- <span *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span> -->
                    </p>
                    <p class="para-1 text-left text-height-4" [innerHTML]="trendingNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="thm-btn mt-md-0 btn-theme-success pt-3 pb-3 GNewsbtn" routerLink="trending-news">More Latest News</button>
        </div>
    </div>
    <div class="tab-pane pt-2 fade" id="news-tab3" role="tabpanel" aria-labelledby="profile-tab">
        <!-- mobile view slider -->
        <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigTrendingNews" *ngIf="trendingLoaded">
                <div ngxSlickItem *ngFor="let trendingNewsData of trendingNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0 CatTag" *ngIf="trendingNewsData.Tag!=''; else BlankTag">{{trendingNewsData.Tag}}</p>
                    <ng-template #BlankTag>
                      <p class="para-2 mb-0 tag"></p>
                    </ng-template>
                    <h4 class="text-height-4 card-header-height-1 ">{{trendingNewsData.Title}}
                    </h4>
                    <p class="para-2 mb-1 sponDate">{{trendingNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4 ">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div>
        <!-- mobile view slider end -->

        <div class="row d-md-flex WrapArt" *ngIf="trendingLoaded">
            <div class="col-md-4 pt-3" *ngFor="let archivedNewsData of archivedNewsDatas">
                <!-- <div class="cursor-p" routerLink='archive-news/{{archivedNewsData.RouteURL}}/{{archivedNewsData.ID}}'> -->
                    <a target="_blank"  routerLink='archive-news/{{archivedNewsData.RouteURL}}/{{archivedNewsData.ID}}'>
                    <img class="img-fluid pb-2 image-hight-news" [src]="archivedNewsData.ImageUrl" alt="news image">
                    <p class="mt-2"><a class="para-2 mb-0">{{archivedNewsData.Tag}}</a></p>
                    <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="archivedNewsData.Title"></h4>
                        </a>
                    <p class="para-2 mb-1">{{archivedNewsData.CompanyName}} | {{archivedNewsData.Date}} </p>
                    <p class="para-1 text-left mb-1 text-height-4" [innerHTML]="archivedNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="thm-btn mt-4 btn-theme-success w-75 pt-3 pb-3">More Archive News</button>
        </div>
    </div>
</div>