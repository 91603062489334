import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';
import { CanonicalService } from 'src/app/canonical.service'; 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data: any;
  HomeMetaTagLoaded: boolean;
  HomeMetaTagData: any;
  envSiteName:any;
  HomeADDurl:SafeResourceUrl;
  urlLoaded :boolean=true;
  //HomeADAfterWhitepaper:SafeResourceUrl;
  //HomeADAfterArticle:SafeResourceUrl;

  constructor(private spinner: NgxSpinnerService,private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,private canonical:CanonicalService,private sanitizer:DomSanitizer
    )
   {this.envSiteName=environment.siteName; }
   

   url:string='https://'+environment.siteName+'/ADcodeFile/HomeTopAD.html'
   //url1:string='https://'+environment.siteName+'/HomeADAfterWhitepaper.html'
   //url2:string='https://'+environment.siteName+'/HomeADAfterArticle.html'
  
   ngOnInit(): void {
    this.AllMetaTagHome();
    
    this.HomeADDurl=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    //try{
      //this.urlLoaded=false;
    //  if(this.url!=null){
    //    this.urlLoaded=true;
    //  }
    //  this.HomeADDurl=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    //}
   // catch{
    //  this.urlLoaded=false;
    //}


    //this.HomeADAfterWhitepaper=this.sanitizer.bypassSecurityTrustResourceUrl(this.url1);
    //this.HomeADAfterArticle=this.sanitizer.bypassSecurityTrustResourceUrl(this.url2);
    // this.spinner.show();
    // setTimeout(() => {
     
    //   this.spinner.hide();
    // }, 4000);
    
  }

  urlNotLoading():void {
    //this.urlLoaded=false;
    document.getElementById('sub-frame-error').style.display="none";
   }

  AllMetaTagHome(): void {
    if(this.HomeMetaTagLoaded!=true){
    this.HomeMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Home')
    .subscribe(
      items => {
        this.HomeMetaTagData = items[0];
        this.HomeMetaTagLoaded=true;
        //console.log('this.HomeMetaTagData',this.HomeMetaTagData);

          //seo code for tags
          this.titleService.setTitle(this.HomeMetaTagData.OgTitle);

          this.canonical.CreateCanonicalLink();

          this.metaTagService.updateTag(
            {name:'robots',content:'index,follow'}
          );

        //  this.metaTagService.updateTag(
        //  { name: 'description', content: this.HomeMetaTagData.OgDescription}
        //  );
         this.metaTagService.updateTag(
           { name: 'HeaderText', content: this.HomeMetaTagData.HeaderText}
           );

          // this.metaTagService.updateTag(
          //   { name: 'title', content: this.HomeMetaTagData.OgTitle}
          // );

          this.metaTagService.updateTag(
            { name: 'keywords', content: this.HomeMetaTagData.OgKeyWords}
          );

         this.metaTagService.updateTag(
           { name: 'image', content: this.HomeMetaTagData.OgImageURL}
         );

         this.metaTagService.addTag(
           { name: 'og:image:alt', content: this.HomeMetaTagData.PageTitle}
         );
         this.metaTagService.updateTag(
          { name: 'og:description', content: this.HomeMetaTagData.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'og:title', content: this.HomeMetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
         { name: 'og:keyword', content: this.HomeMetaTagData.OgKeyWords}
       );
         this.metaTagService.updateTag(
          { name: 'description', content: this.HomeMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'HeaderText', content: this.HomeMetaTagData.HeaderText}
            );
 
           this.metaTagService.addTag(
             { name: 'title', content: this.HomeMetaTagData.OgTitle}
           );
 
           this.metaTagService.updateTag(
             { name: 'keywords', content: this.HomeMetaTagData.OgKeyWords}
           );
 
          this.metaTagService.updateTag(
            { name: 'image', content: this.HomeMetaTagData.OgImageURL}
          );
 
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.HomeMetaTagData.PageTitle}
          );

         this.metaTagService.updateTag(
           { name: 'twitter:description', content: this.HomeMetaTagData.OgDescription}
         );
         this.metaTagService.updateTag(
           { name: 'twitter:title', content: this.HomeMetaTagData.PageTitle}
         );
         this.metaTagService.updateTag(
          { name: 'twitter:keyword', content: this.HomeMetaTagData.OgKeyWords}
        );
         this.metaTagService.updateTag(
           { name: 'twitter:image', content: this.HomeMetaTagData.OgImageURL}
         );
          //seo code for tags end
        });
      }
  }

}
