import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder,  Validators  } from '@angular/forms';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { HttpClient  } from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../../../../src/environments/environment';
import { FooterEmailServices } from 'src/app/footerEmail.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [FooterEmailServices]
})

export class FooterComponent implements OnInit {

  Email_data :any;
  footernewsLetterForm: FormGroup;
  submitted = false;
  ipAddress = '';
  newsLetterSubscribe:any;
  currentYear: any;
  currentDomainName: string;
  masterMetaDatas: any;
  logo: any;
  Flogo: any;
  FaceBook: string;
  Twitter: string;
  LinkedIn: string;
  Site_Name: string;
  envSiteName:any;
  preSite: any;
  @Output() news_email =new EventEmitter();

  constructor(private route: Router,private formBuilder: FormBuilder, private httpclientService: HttpclientService, private http:HttpClient , private newsletter_email:FooterEmailServices , private cookies:CookieService) {
    this.createfooternewsLetterForm();
    this.envSiteName=environment.siteName;
   }

   ngOnInit(): void {
    this.logo = 'https://' + this.envSiteName + '/images/'+ this.envSiteName + '.png';
     this.masterMetaData();
    this.currentDomainName = window.location.href.split('//').pop().split('/')[0];
    this.currentYear = new Date().getFullYear();
    this.getIPAddress();
  }

  get f() { return this.footernewsLetterForm.controls; }

  onSubmit() {
    this.submitted = true;
  

    //this.newsletter_email.setNewsletter_Email(this.footernewsLetterForm.get('email').value);
    var formData: any = new FormData();
    formData.email = this.footernewsLetterForm.get('email').value;     
    formData.acceptTerms= "true";
    formData.Ip = this.ipAddress;
    formData.siteName=this.envSiteName;
  
    if (this.footernewsLetterForm.invalid) {
      document.getElementById("opennewsletterPopupButton").click();
      //this.newsletter_email.setNewsletter_Email(this.footernewsLetterForm.get('email').value);
      //this.cookies.set('Email',this.footernewsLetterForm.get('email').value);
      //console.log(this.newsletter_email.getNewsletterEmail());
      //console.log(this.newsletter_email);

      this.news_email.next(this.footernewsLetterForm.get('email').value);
      console.log(this.news_email);
      return;
  }  

    this.newsLetterSubscribe = false;    
    this.httpclientService.postFormData('https://newapi.nathanark.com/api/AllForms/NewsLetterSignUp',  this.getFormUrlEncodedData(formData))
      .subscribe(
        items => {
          this.newsLetterSubscribe = items;
          //this.createandSaveUserData = true;
          if(this.newsLetterSubscribe==="Created"){
            this.route.navigateByUrl('/thank-you');
          }
        });
  }
 
  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  getFormUrlEncodedData(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
  }

  createfooternewsLetterForm(){
    this.footernewsLetterForm = this.formBuilder.group({               
      email: ['', [Validators.required, Validators.email]]
    });
  }

  masterMetaData(): void
  {
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMasterMetaCode?PubsiteName='+this.envSiteName)
    .subscribe(
      items => {
        
        this.masterMetaDatas = items[0];
        this.preSite = this.masterMetaDatas.PreSite;
        this.FaceBook = items[0].FaceBook;
        this.Twitter = items[0].Twitter;
        this.LinkedIn = items[0].LinkedIn;
        this.Site_Name = items[0].Site_Name;
      });
  }

}
