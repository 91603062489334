<h5 class="" >Other Past Conference</h5>
<div class="row d-none d-md-flex" *ngIf="morePastEventLoaded">
    <div class="col-md-6 mb-5" *ngFor="let morePastEventData of morePastEventDatas">
        <!-- <div class="cursor-p" (click)="refreshReq(morePastEventData.EventID)" routerLink='/events/past-conferences/{{morePastEventData.RouteURL}}/{{morePastEventData.EventID}}'> -->
         <a target="_blank" routerLink='past-conferences/{{morePastEventData.RouteURL}}' class="custom-tooltip">
            <img class="img-fluid pb-2" [src]="morePastEventData.ImageUrl">
            <span class="titleBox"> {{morePastEventData.Name}} </span> 
            <h4 class="text-height-title-3 card-header-height-3" title="{{morePastEventData.Name}}" [innerHTML]="morePastEventData.Name">
            </h4>
            </a>
           <p class="para-2 mb-2">  {{morePastEventData.DateWithStartTime}} | {{morePastEventData.Country}}

                <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
            </p>
            <p class="para-1 text-left mb-1 text-height-4 text-justify" [innerHTML]="morePastEventData.Details"></p>
            <a class="para-2 para-2-c-2 " target="_blank" routerLink='past-conferences/{{morePastEventData.RouteURL}}'></a>
        <!-- </div> -->
    </div>
</div>

