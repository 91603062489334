<div class="row mt-3">
    <div class="text-center add-center" >
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="SerchTopADurl"></iframe></div>
    <div class="col-md-9">
        <p class="para-2-c-2 TopSearch">Search Result Found For "{{keyword}}"</p>
        <div *ngIf="interviewLoad || newsLoad || companiesLoad || upcomingConferencesLoad || pastConferencesLoad || LiveWebinarLoad || OnDemandWebinarLoad || resourcesLoad ;then content else notfound"></div>
        <ng-template #content>
            <div *ngIf="interviewLoad">
                <p class="para-h1">Interviews</p>
                <div class="col-12 d-block p-0 SearchArt">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                        <div ngxSlickItem *ngFor="let interviewDatas of interviewData" class="slide pl-2 pr-2 SearchArtDiv">
                            <!-- <div class="cursor-p" (click)="interviewRedirect(interviewDatas.RouteURL)"> -->
                                  <div class="cursor-p">

                                <a href='{{interviewDatas.RouteURL}}' target="_blank">                                   
                                <img class="img-fluid pb-2 image-hight-news" [src]="interviewDatas.InterviewImage" alt="news image">
                                <!-- <p class="para-2 mb-0 text-uppercase new_global_para height35"></p> -->
                                <!-- <p class="para-2 mb-2">{{interviewDatas.Keywords}}</p> -->
                                <div class="custom-tooltip">
                                    <span class="titleBox mt-2">{{interviewDatas.InterviewTitle}}</span>
                                    <h4 class="text-height-2 card-header-height-2" [innerHTML]="interviewDatas.InterviewTitle"></h4>
                                </div>
                            </a>
                                <p class="para-2 mb-2">{{interviewDatas.CompanyName}} <span *ngIf='interviewDatas.CompanyName!="" && interviewDatas.CompanyName!=" " && interviewDatas.CompanyName!=null 
                                    && interviewDatas.InterviewDate!="" && interviewDatas.InterviewDate!=" " && interviewDatas.InterviewDate!=null'> | </span> {{interviewDatas.InterviewDate}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div *ngIf="newsLoad">
                <p class="para-h1 TopHead">News</p>
                <div class="col-12 d-block p-0 SearchArt search-news">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideNews">
                        <div ngxSlickItem *ngFor="let newsDatas of newsData" class="slide pl-2 pr-2 SearchArtDiv">
                            <div class="cursor-p">
                                <a target="_blank" href='/{{newsDatas.NewsTypeName}}/{{newsDatas.RouteURL}}' >
                                <img class="img-fluid pb-2 image-hight-news" [src]="newsDatas.ImageUrl" alt="news image">
                                <p class="para-2 mb-2 new_global_para">{{newsDatas.Tag}}</p>
                                <div class="custom-tooltip">
                                    <span class="titleBox mt-2">{{newsDatas.Title}}</span>
                                    <h4 class="text-height-2 card-header-height-2" [innerHTML]="newsDatas.Title"></h4>
                                </div>
                               
                                </a>
                                <p class="para-2 mb-2 text-capitalize newsDate">{{newsDatas.CompanyName}} <span *ngIf='newsDatas.CompanyName!="" && newsDatas.CompanyName!=" " && newsDatas.CompanyName!=null 
                                    && newsDatas.Date!="" && newsDatas.Date!=" " && newsDatas.Date!=null'> | </span> {{newsDatas.Date}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div *ngIf="companiesLoad">
                <p class="para-h1 TopHead">Companies</p>
                <div class="col-12 d-block p-0 SearchArt search-company mt-3">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideCompanies">
                        <div ngxSlickItem *ngFor="let companiesDatas of companiesData" class="slide pl-2 pr-2 SearchArtDiv">
                            <div class="cursor-p">
                               <a target="_blank" href='/companies/{{companiesDatas.RouteURL}}'>
                               <div class="CompanyImgParent">
                                <img class="company-img" [src]="companiesDatas.logo" alt="news image">
                             </div>  
                                <h4 class="text-height-2 card-header-height-2 mt-2 comptitle" [innerHTML]="companiesDatas.company_name"></h4>
                             
                       </a>
                        </div>
                    </div>
                        </ngx-slick-carousel>
                    
                </div>
            </div>
            <div *ngIf="upcomingConferencesLoad || pastConferencesLoad || LiveWebinarLoad || OnDemandWebinarLoad">
                <p class="para-h1 TopHead">Events</p>
                <div *ngIf="upcomingConferencesLoad">
                    <h3 class="SubEvent">Upcoming Conferences</h3>
                    <div class="col-12 d-block p-0 SubEventDiv search-event-conf">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let upcomingConferencesDatas of upcomingConferencesData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p" >
                                    <a target="_blank" href='/upcoming-conferences/{{upcomingConferencesDatas.RouteURL}}' >
                                    <img class="img-fluid pb-2 image-hight-news" [src]="upcomingConferencesDatas.ImageUrl" alt="news image">
                                    <p class="para-2 mb-0"></p>
                                    <div class="custom-tooltip">
                                        <span class="titleBox mt-2">{{upcomingConferencesDatas.Name}}</span>
                                        <h4 class="text-height-2 card-header-height-2" [innerHTML]="upcomingConferencesDatas.Name"></h4>
                                    </div>
                                </a>
                                    <p class="para-2 mb-2">{{upcomingConferencesDatas.StartEndDate}} <span *ngIf='upcomingConferencesDatas.StartEndDate!="" && upcomingConferencesDatas.StartEndDate!=" " && upcomingConferencesDatas.StartEndDate!=null 
                                        && upcomingConferencesDatas.Country!="" && upcomingConferencesDatas.Country!=" " && upcomingConferencesDatas.Country!=null'> | </span> {{upcomingConferencesDatas.Country}}
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="pastConferencesLoad">
                    <h3 class="SubEvent">Past Conferences</h3>
                    <div class="col-12 d-block p-0 SubEventDiv search-event-conf">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let pastConferencesDatas of pastConferencesData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p">
                                    <a target="_blank" href='/past-conferences/{{pastConferencesDatas.RouteURL}}'>
                                    <img class="img-fluid pb-2 image-hight-news" [src]="pastConferencesDatas.ImageUrl" alt="news image">
                                    <div class="custom-tooltip">
                                        <span class="titleBox mt-2">{{pastConferencesDatas.Name}}</span>
                                        <h4 class="text-height-2 card-header-height-2 pastTitle" [innerHTML]="pastConferencesDatas.Name"></h4>
                                    </div>
                                </a>
                                    <p class="para-2 mb-2 pastDate">{{pastConferencesDatas.StartEndDate}} <span *ngIf='pastConferencesDatas.StartEndDate!="" && pastConferencesDatas.StartEndDate!=" " && pastConferencesDatas.StartEndDate!=null 
                                        && pastConferencesDatas.Country!="" && pastConferencesDatas.Country!=" " && pastConferencesDatas.Country!=null'> | </span> {{pastConferencesDatas.Country}}
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="LiveWebinarLoad">
                    <h3 class="SubEvent">Live Webinar</h3>
                    <div class="col-12 d-block p-0 SubEventDiv search-event-web">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let LiveWebinarDatas of LiveWebinarData" class="slide pl-2 pr-2 eveSearchDiv">
                                <div class="cursor-p">
                                    <a  target="_blank" href='/live-webinars/{{LiveWebinarDatas.RouteURL}}'>
                                    <img class="img-fluid pb-2 image-hight-news" [src]="LiveWebinarDatas.ImageUrl" alt="news image">
                                    <div class="custom-tooltip">
                                        <span class="titleBox mt-2">{{LiveWebinarDatas.Name}}</span>
                                        <h4 class="text-height-2 card-header-height-2" [innerHTML]="LiveWebinarDatas.Name"></h4>
                                    </div>
                                    
                                    </a>
                                    <p class="para-2 mb-2">{{LiveWebinarDatas.StartEndDate}} <span *ngIf='LiveWebinarDatas.StartEndDate!="" && LiveWebinarDatas.StartEndDate!=" " && LiveWebinarDatas.StartEndDate!=null 
                                        && LiveWebinarDatas.Timezone!="" && LiveWebinarDatas.Timezone!=" " && LiveWebinarDatas.Timezone!=null'> | </span> {{LiveWebinarDatas.Timezone}}
                                    </p>

                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div *ngIf="OnDemandWebinarLoad">
                    <h3 class="text-uppercase SubEvent">On Demand Webinar</h3>
                    <div class="col-12 d-block p-0 SubEventDiv search-event-web">
                        <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                            <div ngxSlickItem *ngFor="let OnDemandWebinarDatas of OnDemandWebinarData" class="slide pl-2 pr-2">
                                <div class="cursor-p" >
                                    <a target="_blank" href='{{OnDemandWebinarDatas.RouteURL}}'>
                                    <img class="img-fluid image-hight-news" [src]="OnDemandWebinarDatas.ImageUrl" alt="news image">
                                    <div class="custom-tooltip">
                                        <span class="titleBox mt-2">{{OnDemandWebinarDatas.Name}}</span>
                                        <h4 class="text-height-2 card-header-height-2 OnDTitle" [innerHTML]="OnDemandWebinarDatas.Name"></h4>
                                    </div>                                    
                                 </a>
                                    <p class="para-2 mb-2">{{OnDemandWebinarDatas.Company}} 
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
            <div *ngIf="resourcesLoad">
                <p class="para-h1 TopHead">Resources</p>
                <div class="col-12 d-block p-0 SearchArt search-resources mt-3">
                    <ngx-slick-carousel class="carousel left-align-slick" #slickModal="slick-carousel" [config]="slideInterview">
                        <div ngxSlickItem *ngFor="let resourcesDatas of resourcesData" class="slide pl-2 pr-2 SearchArtDiv">
                            <div class="cursor-p" >
                                <a target="_blank" href='{{resourcesDatas.RouteURL}}'>
                                <img class="img-fluid pb-2 image-hight-news" [src]="resourcesDatas.ImageUrl" alt="news image">
                                <div class="custom-tooltip">
                                    <span class="titleBox mt-2">{{resourcesDatas.WhitePaperTitle}}</span>
                                    <h4 class="text-height-2 card-header-height-2" [innerHTML]="resourcesDatas.WhitePaperTitle"></h4>
                                </div>
                                
                                </a>
                                <p class="para-2 mb-2 text-capitalize ResoDate">{{resourcesDatas.ResourceType}} <span *ngIf='resourcesDatas.ResourceType!="" && resourcesDatas.ResourceType!=" " && resourcesDatas.ResourceType!=null 
                                    && resourcesDatas.PublishingDate!="" && resourcesDatas.PublishingDate!=" " && resourcesDatas.PublishingDate!=null'> | </span> {{resourcesDatas.PublishingDate}}
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </ng-template>
        <ng-template #notfound>
            <div>
                <p class="para-h1 mb-1">Search Result</p>
                <hr>
                <p class="para-2 text-center">No Result Found</p>
            </div>
        </ng-template>
    </div>
    <div class="col-md-3 text-left" *ngIf="loaded">
        <h2 class="mb-3 SearchSpotL spotGDiv">Spotlight</h2>
        <div class="text-center spotN spotGDiv ">
            <a href="{{spotLightDatas.URL}}" target="_blank">
                <img class="img-fluid" [src]="spotLightDatas.ImageUrl" alt="Responsive image">
                <div class="custom-tooltip">
                    <span class="titleBox mt-2">{{spotLightDatas.Name}}</span>
                    <h4 class="mt-2 text-left">{{spotLightDatas.Name}}</h4>
                </div>
                
            </a>
            <p class="text-left para-1 spotlight-text-height" [innerHTML]=" spotLightDatas.Description"></p>
        </div>
        <div class="text-center ADdiv mb15" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="SerchFirstADafterSpotlight"></iframe>
        </div>
        <div class="text-center ADdiv mb15 d-none d-md-block" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:1050px; border: none;' ng-include [src]="SerchSecondADafterSpotlight"></iframe>
        </div>
    </div>
</div>