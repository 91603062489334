<div class="row">
    <div class="col-md-9">
        <h1 class="mb-0 mt-2">Resources</h1>
        <ul class="nav nav-tabs mobTab" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="home-tab" data-toggle="tab" routerLink="/resources/articles" role="tab" aria-controls="home" aria-selected="false">Articles</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none active" id="profile-tab" data-toggle="tab" routerLink="/resources/videos" role="tab" aria-controls="profile" aria-selected="false">Videos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/whitepapers" aria-controls="profile" aria-selected="false">Whitepapers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/infographics" role="tab" aria-controls="profile" aria-selected="false">Infographics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5" id="profile-tab" data-toggle="tab" routerLink="/resources/webinars" role="tab" aria-controls="profile" aria-selected="false">Webinars</a>
            </li>
        </ul>
        <div class="row mt-5" *ngIf='resourceVideoLoaded==false'>
            <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
                <list-content-loader></list-content-loader>
            </div>
        </div>
        <div class="row d-md-flex mt-3 WrapArt" *ngIf="resourceVideoLoaded">
            <div class="col-md-4 pb-5" *ngFor="let resourcesVideoData of resourcesVideoDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
let i = index
" [class.active]="i == currentIndex">
                <!-- <div class="cursor-p" routerLink='{{resourcesVideoData.RouteURL}}/{{resourcesVideoData.ID}}'> -->
                 <a target="_blank" class="custom-tooltip" routerLink='/videos/{{resourcesVideoData.RouteURL}}'>
                    <img class="img-fluid pb-2 image-hight-news" [src]="resourcesVideoData.ImageUrl" alt="videos image">
                    <!-- <span class="titleBox mt-2"> {{resourcesVideoData.WhitePaperTitle}} </span>  -->
                    <h4 class="text-height-title-3 card-header-height-title-3" title="{{resourcesVideoData.WhitePaperTitle}}" [innerHTML]="resourcesVideoData.WhitePaperTitle"></h4>
                    </a>

                    <p class="para-2 mb-1 sponDate">{{resourcesVideoData.ResourceType |titlecase }} <span *ngIf='resourcesVideoData.ResourceType!="" && resourcesVideoData.PublishingDate1!=""'>|</span> {{resourcesVideoData.PublishingDate1}}
                        <span *ngIf='resourcesVideoData.IsSponcered==true'>| <font>Sponsored</font></span>
                    </p>
                    <p class="para-1 text-left  text-height-4 card-header-height-4 DescPara" [innerHTML]="resourcesVideoData.Description"></p>
                    <a target="_blank" routerLink='/videos/{{resourcesVideoData.RouteURL}}'>
                    <a class="para-2 para-2-c-2 Read_More" target="_blank" routerLink='/videos/{{resourcesVideoData.RouteURL}}'>Watch Now</a>
                </a>
                <!-- </div> -->
            </div>
        </div>
        <div class="col-12 p-0 text-center mt-md-0" *ngIf="count>9">
            <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
        </div>
    </div>
    <div class="col-md-3">
        <app-resources-spotlight></app-resources-spotlight>
        <app-square-ad-videos></app-square-ad-videos>
        <app-resources-event></app-resources-event>
        <app-square-ad-videos></app-square-ad-videos>
    </div>
</div>