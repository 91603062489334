<p class="mb-0 BreadcbTop">
    <a  routerLink="/">Home</a> > 
    <a  routerLink="/events">Events</a> >
     <!-- <a  class="para-2-c-1">Conferences</a> -->
     <a *ngIf="lastString == 'upcoming-conferences'"  routerLink="/events/upcoming-conferences" >Upcoming Conferences</a>
     <a *ngIf="lastString == 'past-conferences'"  routerLink="/events/past-conferences" >Past Conferences</a>
</p>
<h1 class="mb-0" [ngClass]="{'display-none':!(sharedDataService.upComingConferencesFound || sharedDataService.pastConferencesFound)}">Conferences</h1>
<div class="row">
    <div class="col-md-12">
        <ul class="nav nav-tabs mb-3 confTab" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center" [ngClass]="{'display-none':!sharedDataService.upComingConferencesFound}">
                <a class="nav-link pl-2 pr-2  text-center" [class.active]="lastString == 'upcoming-conferences'" id="upcoming-tab" data-toggle="tab" href="#conferances-tab1" routerLink="/events/upcoming-conferences" role="tab" aria-controls="upcoming" aria-selected="true" #UpcomingConferences>upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center" [ngClass]="{'display-none':!sharedDataService.pastConferencesFound}">
                <a class="nav-link pl-md-3 pr-md-4 text-center" [class.active]="lastString == 'past-conferences'" id="past-tab" data-toggle="tab" href="#conferances-tab2" routerLink="/events/past-conferences" role="tab" aria-controls="past" aria-selected="true" #PastConferences>past conferences</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade mt-3" [ngClass]="{'active': lastString == 'upcoming-conferences', 'show': lastString == 'upcoming-conferences','display-none':!sharedDataService.upComingConferencesFound}" id="conferances-tab1" role="tabpanel" aria-labelledby="upcoming-tab" >
                <app-upcoming-conferences></app-upcoming-conferences>
            </div>
            <div class="tab-pane mt-3 fade" [ngClass]="{'active': lastString == 'past-conferences', 'show': lastString == 'past-conferences','display-none':!sharedDataService.pastConferencesFound}" id="conferances-tab2" role="tabpanel" aria-labelledby="past-tab">
                <app-past-conferences></app-past-conferences>
            </div>
        </div>
    </div>
</div>