
<h2 class="mb-0 mt-md-2 SpotEveRes break-word"> C-Suite On Deck</h2>

    <div class="mt-3 WrapArt mTop" *ngIf="cSuiteOnDeckloaded">
        <div class="mb-5" *ngFor="let cSuiteOnDeckData of cSuiteOnDeckDatas">
            <a class="cursor-p custom-tooltip" [href]='cSuiteOnDeckData.RouteURL' target="_blank">
         
            <!-- <div class="cursor-p" routerLink='{{cSuiteOnDeckData.RouteURL}}'> -->
            <img [src]="cSuiteOnDeckData.InterviewImage" class="img-fluid" alt="Responsive image">
            <!-- <span class="titleBox mt-2"> {{cSuiteOnDeckData.InterviewTitle}} </span> -->
            <h4 class="MainTitle text-height-title-3" title="{{cSuiteOnDeckData.InterviewTitle}}">{{cSuiteOnDeckData.InterviewTitle}}</h4>
            </a>
            <!-- <p class="para-2 mb-1">{{cSuiteOnDeckData.Name}} | {{cSuiteOnDeckData.InterviewDate}} </p> -->
            <p class="para-2 mb-1">{{cSuiteOnDeckData.InterviewDate}} </p>
            <p class="text-left para-1 text-height-4 DescPara" [innerHTML]=" cSuiteOnDeckData.InterviewDetails"></p>
            <a class="para-2 para-2-c-2 cursor-p" [href]='cSuiteOnDeckData.RouteURL' target="_blank">Read More</a>
        </div>
    </div>

