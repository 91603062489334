<h2 class="text-left mb-0 mt-md-2 SpotEveRes">
    Spotlight</h2>
<div class="text-left spotGDiv" *ngIf="loaded">
    <div class="cursor-p">
        <!-- (click)="redirect(spotLightDatas.RouteURL)" -->
        <!-- <div class="cursor-p" routerLink='{{spotLightDatas.RouteURL}}'> -->
        <a target="_blank" href="{{spotLightDatas.RouteURL}}">
            <img [src]=" spotLightDatas.ImageUrl " class="img-fluid home-spotlight-logo" alt="Responsive image ">
            <h4 class="mt-2 text-left ">{{spotLightDatas.Name}}</h4>
        </a>
    </div>
    <p class="text-left para-1 spotlight-text-height spotlightPara" [innerHTML]=" spotLightDatas.Description "></p>
</div>