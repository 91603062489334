<div class="d-flex flex-column h-100">
    <!-- <h2 class="text-uppercase mt-4 mb-0">resources</h2>
    <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
        <li class="nav-item w-50 text-center">
            <a target="_blank" class="nav-link active text-center" id="home-tab" data-toggle="tab" href="#resource-tab1" role="tab" aria-controls="home" aria-selected="true">MOST READ</a>
        </li>
        <li class="nav-item w-50">
            <a target="_blank" class="nav-link pl-3 pr-1 text-center" id="profile-tab" data-toggle="tab" href="" routerLink="/resources" role="tab" aria-controls="profile" aria-selected="false">ALL RESOURCES</a>
        </li>

    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-2" id="resource-tab1" role="tabpanel" aria-labelledby="home-tab">
            <!-- mobile view slider article -->
            <!-- <div class="col-12 d-block d-md-none pt-3 p-0">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomeResources" *ngIf="loaded">
                    <div ngxSlickItem *ngFor="let resData of resDatas" class="slide">
                        <div class="text-center">
                            <img class="resources-img-h pb-2" [src]='resData.ImageURL' alt="news image">
                        </div>
                        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
                        <!-- <h4 class="text-uppercase">{{resData.WhitePaperTitle}}</h4>
                        <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>
                    </div>
                </ngx-slick-carousel> -->
                <!-- <div class="mt-2 mb-4">
                    <app-square-ad></app-square-ad>
                </div> 
            </div> -->
            <!-- mobile view slider end -->


            <!-- <div class="row d-none d-md-flex" *ngIf="loaded">
                <div class="col-md-12 pt-1" *ngFor="let resData of resDatas; let  i= index"> -->
                    <!-- <div class="cursor-p" routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                        <!-- <a [href]="resData.RouteURL" target="_blank"> -->
                            <!-- <a  routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                            <!-- <img class="resources-img-h pb-2 pr-5" [src]='resData.ImageURL' alt="news image">

                            <h4 class="text-uppercase">{{resData.WhitePaperTitle}}</h4>
                            <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>
                            </a> -->
                            <!-- <div *ngIf="i==2 || i==5" class="pt-1 pb-3">
                                <app-square-ad></app-square-ad>
                            </div> -->
                        
                    <!-- </div> -->
             <!--</div>
            </div>
        </div>
    </div>-->
    <app-home-whitepapers></app-home-whitepapers>
    <app-home-articles></app-home-articles>
    <button class="mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 MoreResobtn home_btn" routerLink="resources">More Resources</button> 

  

</div>