<h2 class="text-left mb-0 eveSpot SpotEveRes">
    Spotlight</h2>
<div class="text-left spotDiv spotGDiv" *ngIf="TopSpotlightLoaded" >
    <div class="pt-3" *ngFor="let topspotlightData of TopSpotlightDatas">

        <div>
        <a href="{{topspotlightData.URL}}" target="_blank"  class="custom-tooltip">
            <img [src]="topspotlightData.ImageUrl" class="img-fluid home-spotlight-logo" alt="spotlight image">
            <span class="titleBox mt-2"> {{topspotlightData.Name}} </span> 
            <h4 class="mt-2 text-left">{{topspotlightData.Name}}</h4>
        </a>
            </div>
            <p class="text-left para-1 spotlight-text-height spotlightPara" [innerHTML]=" topspotlightData.Description"></p>
        </div>
    </div>
        

<!-- 

        <div class="cursor-p" routerLink='/companies/all-companies/{{topspotlightData.RouteURL}}/{{topspotlightData.EventID}}'>
     
        <img [src]="topspotlightData.ImageUrl" class="img-fluid" alt="Responsive image">
        <h4 class="text-uppercase mt-2 text-center">{{topspotlightData.Name}}</h4>
    </div>
        <p class="text-justify para-1 spotlight-text-height" [innerHTML]="topspotlightData.Description"></p>
    </div>
</div> -->